@charset "UTF-8";
html {
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  box-sizing: border-box;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: "Open Sans";
  background-color: #e0e7e9;
  color: #171717;
  font-size: 1.4rem;
}

#root {
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none !important;
}
*:focus,
*::before:focus,
*::after:focus {
  outline: none !important;
}

.no-data {
  padding: 1.6rem;
  width: 100%;
  display: block;
  text-align: center;
  background-color: #ffffff;
  color: #6a6a6a;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-animation: fade-in 0.5s linear;
  -moz-animation: fade-in 0.5s linear;
  -ms-animation: fade-in 0.5s linear;
  -o-animation: fade-in 0.5s linear;
  animation: fade-in 0.5s linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(25%);
    -webkit-transform: translateY(25%);
    -moz-transform: translateY(25%);
    -ms-transform: translateY(25%);
    -o-transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
}
a {
  text-decoration: unset;
  color: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #757575;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #525252;
}

.global-under-development {
  width: 100%;
  background-color: #e0e7e9;
  opacity: 0.5;
  padding: 2.4rem;
  text-align: center;
  color: #171717;
}

.global__view {
  padding: 2.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.global__error-wrapper {
  padding: 0 1.2rem 0.8rem 1.2rem;
  margin-top: -0.8rem;
}
.global__error {
  color: #fb3350;
  padding-top: 0.4rem;
  font-size: 10px;
  text-align: right;
}
.global__controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 1.2rem auto;
}
.global__controls .om-btn {
  margin-left: 0.4rem;
  margin-top: 0.4rem;
}
.global__header-title-text {
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
  color: #6a6a6a;
}
.global__list {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  -webkit-transition: margin 0.3s linear;
  -moz-transition: margin 0.3s linear;
  -ms-transition: margin 0.3s linear;
  -o-transition: margin 0.3s linear;
  transition: margin 0.3s linear;
}
.global__header {
  display: block;
  padding: 2.4rem 2.4rem 1.2rem 2.4rem;
  width: 100%;
  color: #777777;
  border-bottom: 1px solid #d6d6d6;
  background-color: #ffffff;
}
.global__header--with-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.global__header--with-content .titles {
  display: block;
}
.global__header--with-content .content {
  padding: 0.4rem 0 0.4rem 0.8rem;
}
@media screen and (max-width: 500px) {
  .global__header--with-content {
    display: block;
  }
  .global__header--with-content .titles {
    width: 100%;
  }
}
.global__header--title {
  font-size: 24px;
}
.global__header--title--param {
  font-size: 16px;
}
.global__header--category {
  font-size: 12px;
  font-weight: 600;
}
.global__header--description {
  font-size: 12px;
  font-weight: 100;
}
.global__list-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 0.8rem;
  justify-content: space-between;
  padding: 1.2rem 1.2rem;
  -webkit-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.2);
}
.global__list-item--name {
  font-size: 1.4rem;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1.2rem;
}
.global__list-item--controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.global__list-item--controls--icon {
  margin-left: 0.8rem;
  cursor: pointer;
  display: flex;
  max-width: 16px;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.global__list-item--controls--icon svg {
  pointer-events: none;
}
.global__list-item--controls--icon:hover {
  color: #a93e73;
}
.global__list-item--sorting {
  height: 2.7rem !important;
  margin-top: -1.2rem;
  margin-bottom: -1.2rem;
  margin-left: 1.2rem;
  text-align: center;
  width: 4rem !important;
  padding: 0 !important;
}

.floating-error-backdrop .floating-error-container {
  display: grid;
  padding: 0;
  grid-template-columns: 100%;
}
.floating-error-backdrop .floating-error-container .floating-error__title {
  grid-area: 1/1/2/2;
  position: relative;
  font-size: 2rem;
  font-weight: 500;
  height: 4.1rem;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.4rem 4.8rem 0.4rem 1.2rem;
}
.floating-error-backdrop .floating-error-container .floating-error__title::after {
  background-color: #e0e7e9;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  display: flex;
  width: 4.1rem;
  height: 4.1rem;
  color: #6a6a6a;
  content: "×";
  right: 0;
  top: 0;
}
.floating-error-backdrop .floating-error-container .floating-error__message {
  grid-area: 2/1/3/2;
  padding: 1.2rem;
  margin: 0;
}
.floating-error-backdrop .floating-error-container .floating-error__button {
  color: #ffffff;
  cursor: pointer;
  grid-area: 3/1/4/2;
  margin: 1.2rem auto;
  padding: 0.8rem 2.4rem;
  background-color: #a93e73;
}

html {
  height: auto;
  min-height: 100%;
}

body {
  background-color: #e7ecef;
  background-image: url("./Resources/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  body {
    background-position: center;
  }
}

.spacing {
  width: 100%;
  height: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 0.8rem;
  background-color: #e0e7e9;
  color: #777777;
}

.error-container {
  background-color: #ffffff;
  width: 100%;
  margin: 1.2rem 0;
  color: #fb3350;
}
.error-container__oneline-text {
  text-align: center;
}
.error-container__redline {
  text-align: center;
}
.error-container__button .error-ok-button {
  cursor: pointer;
  background-color: #d6d6d6;
  padding: 0.8rem 1.6rem;
}
.error-container__button .error-ok-button:hover {
  background-color: #a93e73;
  color: #ffffff;
}

.global__space-after {
  padding-bottom: 1.6rem;
  background-color: rgba(255, 255, 255, 0.5);
}

.warn-page-wrapper {
  padding-top: 15%;
}

.warn-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 1.2rem 2.4rem;
}
.warn-page__title {
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.warn-page__title span {
  font-size: 40px;
}
.warn-page__container--text {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  margin: 10px auto;
  color: #888;
}
.warn-page__container--text span {
  font-weight: 600;
  font-size: 16px;
}
.warn-page__container--buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.warn-page__container--buttons .has-left-margin {
  margin-left: 10px;
}

@media screen and (max-width: 1000px) {
  .warn-page__container--buttons {
    flex-direction: column;
    align-items: center;
  }
  .warn-page__container--buttons .bx--btn {
    width: 100%;
    margin-top: 10px;
  }
  .warn-page__container--buttons .has-left-margin {
    margin-left: 0;
  }
}
.navbar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.main-page .restaurant-block {
  padding-bottom: 55%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.main-page .restaurant-block:hover {
  opacity: 1;
}
@media screen and (max-width: 991px) {
  .main-page .restaurant-block {
    padding-bottom: 70%;
  }
}
.main-page .align-right {
  background-position: center right;
}
.main-page .align-left {
  background-position: center left;
}

.main-page-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6.4rem;
  padding: 2.4rem 2.4rem;
  position: absolute;
  align-items: center;
  width: 100%;
  min-height: 100%;
}
@media screen and (max-width: 768px) {
  .main-page-footer {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 1.2rem;
    padding: 2.4rem;
    position: relative;
  }
  .main-page-footer .align-left,
.main-page-footer .align-right {
    background-position: center;
  }
}
